import React from 'react'
import { Link } from 'gatsby'
import { Spring } from 'react-spring'
import { delay } from 'delay'

const Header = ({ siteTitle }) => (

  <nav
  class="grid-container-navbar"
  id="navbar"
  >


  <div
  class="grid-item"
  id="grid-1"
  >
  <Spring
  delay={1000}
  from={{ height: 0, opacity: 0, top: -50 }}
  to={{ height: 80, opacity: 1, top: 8}}
  >
  {({ height, opacity, top }) =>

  <Link
    to="/"
  >
    <div
    id="nameLogo"
    style={{ height, opacity }}
    >
      <h1 class="navLink" style={{ margin: 0, top }}>
          {siteTitle}
      </h1>
    </div>
    </Link>

  }
    </Spring>
    </div>

    <div
    class="grid-item" id="grid-2">
    </div>

    <div
    class="grid-item" id="grid-3">
    </div>

    <div
    class="grid-item" id="grid-4">

    </div>

    <div
    class="grid-item" id="grid-5">
    
    </div>

  </nav>
)

export default Header
